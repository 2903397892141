.title {
    border-bottom: 1px solid #1c1d213a;
    color: #1C1D21;
    margin: 0;
    /* font-weight: 500; */
}

.content {
    padding:1em;
    display:flex;
    flex-direction: column;
}
.content p {
    /* color:#1C1D21; */
    color: #000000;
}
.content h3 {
    font-size: 1em;
    font-weight:350;
    color:#1C1D21;
} 

.quote {
    text-align: center;
    align-self: center;
    padding: 2ch 1ch;
    font-size: .9em;
    font-weight: 300;
    /* color:#1C1D21; */
    /* margin: 0 min(1em); */
    width: clamp(30ch, 90%, 40ch);
    border-radius: 10px;
    /* border: 1px solid #84878a; */ /* Purple Border */
    /* /border: 1px solid #1c1d213a; */
    background-color:#ffffff30;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.seal{
    max-width:75%;
    height:auto;
    align-self: center;
    border-radius: .5em;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    margin-top: 1em;
}