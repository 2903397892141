.container{
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 1.2rem;
    gap: 1rem;
    /* background-color: #ffffff50; */
    padding: .5rem;
    /* border: 1px solid #1c1d213a; */
    border-radius: .3rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}.container h3{
    margin: 0;
    font-weight: 400;
    overflow: hidden;
}.container p{
    margin:0;
    font-size: .8em;
    overflow: hidden;
}.container:hover{
    /* background-color: #d9d9d950; */
    background-color: #ffffff24;
    cursor:pointer;
}.container:active{
    background-color: #cacaca45;
    font-size: .99rem;
    /* padding:.6rem; */
}

.animation-container {
    display:flex;
    justify-content: center;
    align-items:center;
    min-height:4.2rem;
}

@media screen and (max-width: 800px) {
    .container {font-size:.9rem;}
}


.picture-box {
    /* pointer-events: none; */
    display: flex;
    align-items: center;
    /* max-height:4em;
    max-width:4em; */
    width:32px;
    height:32px;
    border-radius: .3em;
    background-color: black;
    overflow: hidden;
    /* object-fit:cover;     */
    /* object-position: 50 50; */
}
.profile-pic {
    /* pointer-events: none; */
    width:32px;
    height:32px;
}
.name-date {
    /* pointer-events: none; */
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height:4em;
    /* min-width:20%;
    max-width:40%; */
    text-wrap: nowrap;
    overflow:hidden;
    /* background-color: pink; */
}

.description {
    margin: 0 0 0 0;
    max-width:60%;
    /* margin-top: .em; */
    /* background-color: blue; */
}