.App {
  display: flex;
  flex-direction: row;
  /* background-color: rgba(0, 255, 255, 0.10); */ /*Original IceMode*/
  /* background-color: #07a2aa2e;*/ /*Original IceMode*/
  background-color: #07a2aa2e; 
  /* background-color: #6607aa2e; */ /*PURPLE MODE*/
  min-height: 100vh;
  padding-top: 1em;
}.left-column {
  display:flex;
  /* position: sticky;
  top: 20px;  */
  justify-content: flex-end;
  width:20rem;
}.center-column {
  display:flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-width: 40ch;
}.right-column{
  display:flex;
  width: 20rem;
}

@media screen and (max-width: 800px) {
  .right-column{ display:none; }
  .left-column{ display:none; }
}

.page-content {
  flex:1;
  width:95%;
  /* border: 1px solid rgb(202, 110, 223); */ /* Original purple mode border */
  /* box-shadow: 0px 0px 4px rgb(202, 110, 223); */
  border-radius: .8em;
  padding:1em;
}