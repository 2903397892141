.menu {
    display: flex;
    /* flex-direction: row; */
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 1em;
}.menu ul {
    list-style-type:none;
    padding: 0;
    border-right:1px solid #1c1d213a;
}.menu li {
    padding: 1rem 1rem
}

.myButton {
	cursor:pointer;
    border-radius:3px;
	font-size:13px;
    color:#1c1d2181;    
    font-size:.9em;
	font-family:Arial;
	text-decoration:none;
	text-shadow:0px 1px 0px #e0e0e0;
}.myButton:hover {
    color:#cacaca;
    text-shadow:0px 1px 0px #ffffff;
}.myButton:active {
    color:#1C1D21;
    text-shadow:0px 1px 0px #ffffff;
}