.slide {
    display:flex;
    gap:1em;
    height: 100vh;
    overflow: hidden;
    margin: min(5em) max(0em)
    /* align-items: center; */
    /* background-color: aliceblue; */
}

/* .slide h1 {
    margin:0
} */

.yt-short {
    border-radius:1em;
    overflow:hidden;
    background-color:black;
    box-shadow: 0px 0px 4px rgb(0, 0, 0, .5);
}

.short {
    width:20em;
    height:35.53em;
}

.small-short {
    width:9em; 
    height:15.894em;
}

.short-row {
    display: flex;
    justify-content: space-between;
    gap: 2em;
}

.cp-description {
    display:flex;
    flex-direction: column;
    /* max-height: 100%; */
    /* background-color: #f0f8ff; */
    /* padding:.5em;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: .5em */
} .cp-description h3 {
    overflow: wrap;
}
.cp-container {
    display: flex;
    flex-direction: column;
}

.abbey-quote {
    text-align: center;
    align-self: center;
    /* justify-self:flex-end; */
    padding: 5ch 2ch;
    font-size: .9em;
    font-weight: 300;
    /* width: clamp(30ch, 90%, 40ch); */
    /* width */
    border-radius: 10px;
    border: 1px solid rgb(255, 136, 213); /* Purple Border */
    /* /border: 1px solid #1c1d213a; */
    background-color:rgba(255, 182, 229, 0.123);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.scheduling-container {
    display:flex;
    justify-content: space-between;
    /* background-color: blue; */
}
.schedule-left {
    display:flex;
    flex-direction:column;
    gap:1em;
    width:40%;
}
.schedule-right {
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
}