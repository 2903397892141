.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5em 1em;
    /* box-shadow: 0px 5px 5px #80808020; */
    border-bottom: 1px solid #A288A6;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}.logo{
    /* width: 64px;
    height: 48px;*/
    width:18%;
    height:18%;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
}

.nav-buttons {
    /* margin-right: 1em; */
}.nav-buttons ul {
    display:flex;
    flex-direction: row;
    list-style-type:none;
    padding: 0;
}.nav-buttons li {
    padding: 0 1rem;
}

.nav-button {
	cursor:pointer;
    border-radius:3px;
    color:#1c1d2181;    
    font-size:.9em;
	font-family:Arial;
	text-decoration:none;
	text-shadow:0px 1px 0px #e0e0e0;
}.nav-button:hover {
    color:#cacaca;
    text-shadow:0px 1px 0px #ffffff;
}.nav-button:active {
    color:#1C1D21;
    text-shadow:0px 1px 0px #ffffff;
} 

.search-bar{

}.search-bar button {
    background-color: white;
    /* border-radius: 1em;
    border: 1px solid #84878a; */
    border-radius: 0;
    border: none;
}.search-bar input {
    border-width: 0 0 1px 0;
    border-color: #84878a;
    margin-right: 0;
}.search-bar input:active {         /* what is this type of css block called? It responds to state. what are all options? */
    background-color: #000000;
}.search-icon{
    width: 1em;
    height: 1em;
}