.project-list {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.project-panel {
    display:flex;
}

.last-column{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* background-color: blue; */
}
.next-column{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.back-button:hover{
    cursor:pointer;
}